








import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { CommentsViewModel } from '../../../viewmodels/comments-viewmodel'

@Observer
@Component({
  components: {
    'comment-item': () => import('./comment-item.vue'),
  },
})
export default class CommentList extends Vue {
  @Inject() vm!: CommentsViewModel
}
